import { useState } from "react";
import { useSubmitForm } from "hooks/useSubmitform";

import SendButton from "components/SendButton";

import formDataList from "./formDataList.json";
import { ExperienceIcon, TrailerIcon } from "assets/icons";
import { ShareExpScreenRoot } from "styles/styles";

const icons = {
  experience: <ExperienceIcon />,
  preferredTrailer: <TrailerIcon />,
};

const ShareExpFirstScreen = ({ onNextStep, customer }) => {
  const [selectFields, setSelectedFields] = useState({});

  const [{ loading, error }, submitData] = useSubmitForm({
    method: "updateLead",
    path: "/form/2",
    callback: () => onNextStep(),
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    submitData({
      customerId: customer.customerId,
      step: "Share us your experience 1",
      ...selectFields,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSelectedFields((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <ShareExpScreenRoot>
      <h1 className="title">Share us your experience </h1>
      <p className="description" style={{ marginBottom: "4rem" }}>
        Fill it out completely and find out the results of our owner-operator
      </p>
      <form onSubmit={handleSubmit}>
        {formDataList.map((item) => {
          const { title, name } = item;
          return (
            <div key={name} className="form-row mb-2">
              <div className="input-group-container">
                <p className="input-group-name">
                  {icons[name]} {title}
                </p>
                <div className="input-radio-list">
                  {item.options.map(({ radioName }) => {
                    const modRadioName = radioName.replace(/\s/g, "");
                    return (
                      <div
                        key={radioName}
                        className={`input-radio-item ${
                          selectFields?.[name] === radioName
                            ? "input-radio-item--active"
                            : ""
                        }`}
                      >
                        <input
                          type="radio"
                          value={radioName}
                          name={name}
                          id={`${name}${modRadioName}`}
                          onChange={handleChange}
                        />
                        <label htmlFor={`${name}${modRadioName}`}>
                          {radioName}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          );
        })}

        <SendButton
          id="step-2"
          error={error}
          disabled={loading}
          type="submit"
          conditionalClasses={loading ? "inactive" : ""}
        >
          Continue
        </SendButton>
      </form>
    </ShareExpScreenRoot>
  );
};

export default ShareExpFirstScreen;
