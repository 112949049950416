import { useEffect, useRef, useState } from "react";
import api from "services";
export const useDidMount = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const customerRef = useRef({
    fullName: "",
    phone: "",
    customerId: "",
    email: ""
  });
  const urlParams = new URLSearchParams(window.location.search);
  const customerName = urlParams.get("fullName");
  const isFetching = loading && customerName ? true : false;
  const defaultStep = customerName ? 1 : 0;

  useEffect(() => {
    if (customerName) {
      api
        .createLead("/form/1", {})
        .then((values) => {
          setCustomer(values);
        })
        .catch((error) => {
          setError(error);
        })
        .finally(() => setLoading(false));
    }
  }, []);

  function setCustomer(values) {
    customerRef.current = { ...customerRef.current, ...values };
  }
  function getCustomer() {
    return customerRef.current;
  }

  return { isFetching, error, defaultStep, setCustomer, getCustomer };
};
