
import styled from "styles";
import loadable from "@loadable/component";
const ReactPlayer = loadable(() => import("react-player"));

const VideoPlayerRoot = styled.div`
    position: relative;
    padding-top: 56.25%;
    background-color: #eee;

    .player {
      position: absolute;
      top: 0;
      iframe {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
`;

const VideoPlayer = (props) => {
  return (
    <VideoPlayerRoot>
      <ReactPlayer
        width="100%"
        height="100%"
        className="player"
        {...props}
      />
    </VideoPlayerRoot>
  );
};

export default VideoPlayer;
