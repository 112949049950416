import { createElement, useState } from "react";
import { useDidMount } from "hooks/useDidMount";

import { AnimatePresence } from "framer-motion";
import AnimatedScreen from "components/AnimatedScreen";

import ProgressIndicator from "components/ProgressIndicator";
// import IntroScreen from "screens/IntroScreen";
import JoinOurTeamScreen from "screens/JoinOurTeamScreen";
import ShareExpFirstScreen from "screens/ShareExpFirstScreen";
import ShareExpSecondScreen from "screens/ShareExpSecondScreen";
import ShareExpThirdScreen from "screens/ShareExpThirdScreen";
import GetSolutionsScreen from "screens/GetSolutionsScreen";
import ExclusiveServiceScreen from "screens/ExclusiveServiceScreen";
import InterviewScreen from "screens/InterviewScreen";
import CdlScreen from "screens/CdlScreen";
import SocialMediaScreen from "screens/SocialMediaScreen";
// import TermsAndConditions from "screens/TermsAndConditions";
import Spinner from "components/Spinner";

import { Logo } from "assets/icons";
import { MainContainer } from "styles/app.style";
import ErrorBoundary from "components/ErrorBoundary";

const comp = [
  // IntroScreen,
  JoinOurTeamScreen,
  ExclusiveServiceScreen,
  ShareExpFirstScreen,
  ShareExpSecondScreen,
  ShareExpThirdScreen,
  GetSolutionsScreen,
  InterviewScreen,
  CdlScreen,
  SocialMediaScreen,
  // TermsAndConditions,
];

const stepCount = comp.length - 1;

function App() {
  const { isFetching, error, defaultStep, setCustomer, getCustomer } =
    useDidMount();

  const [step, setStep] = useState(defaultStep);

  if (isFetching) {
    return (
      <div className="centered">
        <Spinner />
      </div>
    );
  }
  if (error) return <ErrorBoundary />;

  const handleNextStep = (step) => {
    setStep((prevStep) => (typeof step === "number" ? step : prevStep + 1));
  };

  const showProgressIndicator = 0 < step && step <= stepCount;
  return (
    // <MainContainer className={step === 0 ? "extra-class" : null}>
    <MainContainer>
      <div className={"secondary-logo"}>
        <Logo />
      </div>

      {showProgressIndicator && (
        <div style={{ marginBottom: "3.2rem" }}>
          <ProgressIndicator step={step} allSteps={stepCount} />
        </div>
      )}

      <AnimatePresence>
        <AnimatedScreen screenKey={step}>
          {createElement(comp[step], {
            onNextStep: handleNextStep,
            customer: getCustomer(),
            setCustomer: setCustomer,
          })}
        </AnimatedScreen>
      </AnimatePresence>
    </MainContainer>
  );
}

export default App;
