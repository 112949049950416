import { useState, useRef, useEffect } from "react";
import { useField } from "formik";

import Spinner from "components/Spinner";
import UsageButton from "components/SendButton";

import infoImg from "assets/images/cdl-illinois-img.png";
import { ReactComponent as MarkIcon } from "assets/icons/mark-icon.svg";
import { FileUploadContainer } from "styles/styles";

const PreviewImage = ({ file }) => {
  const [preview, setPreview] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getImage = async () => {
      setLoading(true);
      let url = { file, size: file.size, name: file.name };
      if (typeof file === "string") {
        const data = await fetch(file)
          .then((res) => res.blob())
          .catch((err) => console.log(err));
        url = { file: data, size: data.size, name: "photo" };
      }
      const reader = new FileReader();
      reader.readAsDataURL(url.file);
      reader.onloadend = () => {
        setLoading(false);
        setPreview({ src: reader.result });
      };
    };
    getImage();
  }, [file]);

  return (
    <>
      <div className="spinner-wrap">{loading && <Spinner />}</div>
      {preview && !loading && (
        <img src={preview.src} alt="your-CDL" height={73} width={73} />
      )}
    </>
  );
};

const FileUpload = ({ name, file, errorSubmit, loadingSubmit, ...rest }) => {
  const [{ value, ...fields }, meta] = useField(name);
  const fileRef = useRef();

  return (
    <FileUploadContainer>
      <div>
        {file && !meta?.error ? (
          <PreviewImage file={file} metaError={meta?.error} />
        ) : (
          <div className="info-container">
            <div className="img-wrap">
              <img src={infoImg} alt="driver-license-img" />
            </div>
            <h2>We will check your CDL for:</h2>
            <ul>
              <li>
                <MarkIcon />
                <span>Background</span>
              </li>
              <li>
                <MarkIcon />
                <span>Drug test</span>
              </li>
              <li>
                <MarkIcon />
                <span>MVR Check</span>
              </li>
            </ul>
          </div>
        )}
      </div>
      <div style={{ width: "100%", position: "relative" }}>
        <input ref={fileRef} hidden {...rest} />

        <UsageButton
          type="button"
          variant="dark-reverse-btn"
          error={meta.touched && meta.error}
          disabled={loadingSubmit}
          onClick={() => fileRef.current.click()}
          {...fields}
        >
          Upload
        </UsageButton>
        {file && !meta?.error && (
          <UsageButton
            id="step-8"
            error={errorSubmit}
            disabled={loadingSubmit}
            type="submit"
            conditionalClasses={loadingSubmit ? "inactive" : ""}
          >
            Confirm
          </UsageButton>
        )}
      </div>
    </FileUploadContainer>
  );
};

export default FileUpload;
