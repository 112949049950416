import { SpinnerContainer } from "styles/styles";


const Spinner = () => {
  return (
    <SpinnerContainer >
      <div className="ldio-535l3tci9g7">
        <div></div>
      </div>
    </SpinnerContainer>
  );
};

export default Spinner;
