import React from "react";

const ErrorBoundary = ({ clx = "" }) => {
  return (
    <div className={`error-boundary${clx ? " " + clx : ""}`}>
      <h3>Oops!</h3>
      <h4>That's our bad(</h4>
      <p>We're not exactly what happened, but something went wrong.</p>
      <p>You may also refresh the page or try again later</p>
    </div>
  );
};

export default ErrorBoundary;
