import React, { useState, useMemo } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import styled from "styles";

const AutocompleteWrap = styled.div`
  width: 100%;
  position: relative;

  input {
    width: 100%;
    height: 4rem;
    padding: 1.05rem 0;
    font-size: 1.6rem;
    letter-spacing: -0.015em;
    color: #121316;
    caret-color: ${(props) => `${props.theme.mainColor}`};
    border-bottom: 1px solid #e5e5e5;
    transition: 0.2s ease-in-out;
    &::placeholder {
      color: #787883;
      opacity: 1;
    }
    &:focus {
      border-color: #121316;
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
      -webkit-text-fill-color: #121316 !important;
    }
  }
`;

const AutoComplete = ({ placeholder, setField, getfiltered, name }) => {
  const [query, setQuery] = useState("");

  const list = useMemo(() => {
    if (query) return getfiltered(name, query);
    return [];
  }, [query]);

  return (
    <AutocompleteWrap>
      <Autocomplete
        disableClearable
        freeSolo
        forcePopupIcon={false}
        options={list}
        onChange={(e, values) => setQuery(values)}
        renderInput={(props) => (
          <TextField
            {...props}
            InputProps={{
              ...props.InputProps,
              disableUnderline: true,
              style: { fontSize: `30 !important` },
            }}
            variant="standard"
            InputLabelProps={{
              ...props.InputLabelProps,
              shrink: false,
            }}
            name={name}
            onBlur={setField}
            onChange={(e) => setQuery(e.target.value)}
            placeholder={placeholder}
          />
        )}
      />
    </AutocompleteWrap>
  );
};

export default AutoComplete;
