import { Formik } from "formik";
import { getCustomerFormSchema } from "validations";

import { useSubmitForm } from "hooks/useSubmitform";

import SendButton from "components/SendButton";
import { PhoneField, TextField } from "components/FormFields";

import { JoinOurTeamRoot } from "styles/styles";

const JoinOurTeamScreen = ({ onNextStep, setCustomer }) => {
  const [{ loading, error }, handleSubmit] = useSubmitForm({
    method: "createLead",
    path: "/form/1",
    callback: (values) => {
      setCustomer(values);
      onNextStep();
    },
  });
  return (
    <JoinOurTeamRoot>
      <h1 className="title">Join our team</h1>
      <p className="description" style={{ marginBottom: "5rem" }}>
        Fill out the form and our specialists will promptly reach out to provide
        you with Exclusive Service only for <span>Owner Operators</span>
      </p>

      <Formik
        initialValues={{
          fullName: "",
          phone: "",
        }}
        validationSchema={getCustomerFormSchema()}
        onSubmit={handleSubmit}
      >
        {({ values, handleSubmit, setFieldValue, handleBlur }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className="form-row">
                <TextField name="fullName" placeholder="Full name" />
              </div>
              <div className="form-row">
                <PhoneField
                  name="phone"
                  value={values.phone}
                  placeholder="Phone"
                  onChange={setFieldValue}
                  onBlur={handleBlur}
                />
              </div>

              {/* <div className="form-row" style={{ marginBottom: "4.8rem" }}>
                <TextField name="email" placeholder="Email" />
              </div> */}

              {/* <label className="copyright" style={{ marginBottom: "3.2rem" }}>
                <input
                  type="checkbox"
                  name={`${values.agreeing}`}
                  checked={values.agreeing}
                  onChange={(e) => setFieldValue("agreeing", e.target.checked)}
                />
                <div>
                  By confirm you are agreeing to our{" "}
                  <span onClick={() => onNextStep(10)}>
                    Terms and Conditions
                  </span>
                </div>
              </label> */}

              <SendButton
                error={error}
                disabled={loading || values.check}
                type="submit"
                conditionalClasses={loading ? "inactive" : ""}
                id="step-1"
              >
                Next Step
              </SendButton>
            </form>
          );
        }}
      </Formik>
    </JoinOurTeamRoot>
  );
};

export default JoinOurTeamScreen;
